@media (min-width: 640px) {
    body {
        background-image: url('/seventh.jpeg');
        height: 100%;
        width: 100%;

        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: rgba(255,255,255,0.8);
        background-blend-mode: lighten;
    }

    .big-font {
        font-size:18px;
    }

    .emphasis-font {
        font-size: 16px;
        font-weight: 700;
        color: #2E407A;
    }

    .small-font {
        font-size: 9px;
    }
}

@media (max-width: 640px) {
    .table {
        display: block;
    }

    span {
        font-size: 13px;
    }

    .big-font {
        font-size:14px;
    }

    .emphasis-font {
        font-weight: 700;
        color: #2E407A;
    }

    .small-font {
        font-size: 7px;
    }
}

tr:nth-child(odd) {background-color: white;}
tr:nth-child(even) {background-color: #f2f2f2;}


.text-center {
    text-align: center;
}

.right-border {
    border-right: 1px solid black;
}

.velocity-font {
    color: #424242;
}

.name-column {
    min-width: 80px;
}

.table {
    margin: auto;
    width: 100%;
    max-width:1000px;
    border-collapse: collapse;
    overflow-x: auto;
}

td {
    text-align: center;
    vertical-align: middle;
    min-width: 40px;
}

thead th {
  text-align: center;
  border-bottom: 2px solid black;
}

thead th.ascending::after {
  content: '▾';
  display: inline-block;
  margin-left: 1em;
}

thead th.descending::after {
  content: '▴';
  display: inline-block;
  margin-left: 1em;
}
